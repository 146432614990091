var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen container mx-auto min-contents" }, [
    _c("div", { staticClass: "w-full rounded overflow-hidden my-4" }, [
      _c("div", { staticClass: "px-6 py-4" }, [
        _c("h2", { staticClass: "text-center font-bold text-3xl" }, [
          _vm._v(_vm._s(_vm.$t("pages.help.flow.title"))),
        ]),
      ]),
    ]),
    _c(
      "ul",
      {
        staticClass: "grid grid-cols-2 gap-x-4 h-auto",
        attrs: { id: "help-active" },
      },
      [
        _c("li", { staticClass: "col-span-1" }, [
          _c(
            "span",
            {
              staticClass:
                "h-full text-center block border border-b-0 py-2 px-4",
              class:
                _vm.selectedIndex === 0
                  ? "border-base-yellow bg-base-yellow text-black"
                  : "text-gray-400",
              on: {
                click: function ($event) {
                  _vm.selectedIndex = 0
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("pages.help.flow.tab-ch")) + " ")]
          ),
        ]),
        _c("li", { staticClass: "col-span-1" }, [
          _c(
            "span",
            {
              staticClass:
                "h-full text-center block border border-b-0 py-2 px-4",
              class:
                _vm.selectedIndex === 1
                  ? "border-base-yellow bg-base-yellow text-black"
                  : "text-gray-400",
              on: {
                click: function ($event) {
                  _vm.selectedIndex = 1
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("pages.help.flow.tab-buyer")) + " ")]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "help-sumally" }, [
      _c(
        "div",
        { staticClass: "help-index border-2 border-base-yellow mb-8 p-4" },
        [
          _vm.selectedIndex === 0
            ? [
                _c(
                  "h3",
                  { staticClass: "font-bold text-2xl text-center mb-4" },
                  [_vm._v("主な流れ")]
                ),
                _c("ul", { staticClass: "bg-light-grey p-8" }, [
                  _c("li", { staticClass: "mb-8" }, [
                    _c("p", { staticClass: "font-bold text-lg" }, [
                      _vm._v("1: 新規アカウント登録"),
                    ]),
                    _c("p", {}, [
                      _vm._v(
                        "映画ご提供者様のご登録をお願いいたします。メールアドレスが必要になります。"
                      ),
                    ]),
                    _c(
                      "p",
                      { staticClass: "mb-3 mt-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/ch/account" },
                          },
                          [_vm._v(" ? 新規アカウント登録について ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/ch/password" },
                          },
                          [_vm._v(" ? アカウントのパスワードを忘れました ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/ch/email" },
                          },
                          [
                            _vm._v(
                              " ? 新規アカウント登録完了メールが届きません "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("li", { staticClass: "mb-8" }, [
                    _c("p", { staticClass: "font-bold text-lg" }, [
                      _vm._v("2: 作品登録"),
                    ]),
                    _c("p", {}, [
                      _vm._v(
                        "ご登録が完了しましたら、ご提供いただける映画の情報をご登録ください。"
                      ),
                    ]),
                    _c(
                      "p",
                      { staticClass: "mb-3 mt-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 mt-3 underline",
                            attrs: { to: "/help/ch/product-register" },
                          },
                          [_vm._v(" ? 作品登録(単体登録)について ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/ch/product-register-all" },
                          },
                          [_vm._v(" ? 作品登録(一括登録)について ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/ch/product-list" },
                          },
                          [_vm._v(" ? 映画一覧の確認と編集について ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("li", { staticClass: "mb-8" }, [
                    _c("p", { staticClass: "font-bold text-lg" }, [
                      _vm._v("3: 販売"),
                    ]),
                    _c("p", [
                      _vm._v(
                        "ご登録いただいた映画の購入希望者が現れましたら、弊社よりご連絡させていただきます。"
                      ),
                    ]),
                    _vm._m(0),
                    _c(
                      "p",
                      { staticClass: "mb-3 mt-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 mt-3 underline",
                            attrs: { to: "/help/ch/quotation" },
                          },
                          [_vm._v(" ? 見積書作成について ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/ch/upload" },
                          },
                          [_vm._v(" ? 納品アップロードについて ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/ch/invoice" },
                          },
                          [_vm._v(" ? 請求書について ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("li", { staticClass: "mb-8" }, [
                    _c("p", { staticClass: "font-bold text-lg" }, [
                      _vm._v("4: 外部サイトでの動画のアップロードについて"),
                    ]),
                    _c(
                      "p",
                      { staticClass: "mb-3 mt-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 mt-3 underline",
                            attrs: { to: "/help/ch/youtube-limit" },
                          },
                          [
                            _vm._v(
                              " ? Youtubeでの限定公開の方法がわかりません "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/ch/vimeo-register" },
                          },
                          [_vm._v(" ? Vimeoで動画登録を行いたいです ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/ch/vimeo-limit" },
                          },
                          [_vm._v(" ? Vimeoでの限定公開の方法がわかりません ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("li", [
                    _c("p", { staticClass: "font-bold text-lg" }, [
                      _vm._v("5: よくあるご質問"),
                    ]),
                    _c("p", { staticClass: "mb-2" }, [
                      _vm._v(
                        "よくあるご質問は以下にまとめてありますので、お役立てください。"
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "w-full grid grid-cols-3 gap-6" },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "col-span-1 border rounded-lg p-2 cursor-pointer relative overflow-hidden h-32",
                            attrs: { href: "/faq", target: "_blank" },
                          },
                          [
                            _c("img", {
                              staticClass: "absolute inset-0 object-cover",
                              attrs: {
                                src: require("@/assets/image/contact_faq.jpg"),
                              },
                            }),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-white absolute bottom-2 left-2 text-shadow",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.pulldownModal.help.faq"
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "svg",
                                  {
                                    staticClass: "w-5 h-5 pb-1 inline",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      fill: "none",
                                      viewBox: "0 0 24 24",
                                      "stroke-width": "1.5",
                                      stroke: "currentColor",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        d: "M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _c("div", {
                              staticClass:
                                "absolute inset-0 bg-white bg-opacity-0 hover:bg-opacity-20 active:bg-black",
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            : _vm.selectedIndex === 1
            ? [
                _c(
                  "h3",
                  { staticClass: "font-bold text-2xl text-center mb-4" },
                  [_vm._v("Operating procedure")]
                ),
                _c("ul", { staticClass: "bg-light-grey p-8" }, [
                  _c("li", { staticClass: "mb-8" }, [
                    _c("p", { staticClass: "font-bold text-lg" }, [
                      _vm._v("1:Create your account"),
                    ]),
                    _vm._m(1),
                    _c(
                      "p",
                      { staticClass: "mb-3 mt-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/vod/account" },
                          },
                          [_vm._v(" ? Create your account ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/vod/email" },
                          },
                          [_vm._v(" ? Don't recieved a reset email? ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/vod/password" },
                          },
                          [_vm._v(" ? Lost Password? ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("li", { staticClass: "mb-8" }, [
                    _c("p", { staticClass: "font-bold text-lg" }, [
                      _vm._v("2:Request Quote"),
                    ]),
                    _c("p", {}, [
                      _vm._v(
                        "After the account setting, request a quote for films you would like to buy."
                      ),
                    ]),
                    _c(
                      "p",
                      { staticClass: "mb-3 mt-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/vod/quote" },
                          },
                          [_vm._v(" ? Request Quote ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/vod/quote-save-condition" },
                          },
                          [
                            _vm._v(
                              " ? Saved condition function for Request Quote "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/vod/quote-proposal" },
                          },
                          [
                            _vm._v(
                              " ? Request Quote from our proposal(recommend movie list) "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/vod/quote-check" },
                          },
                          [_vm._v(" ? Check Quote ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("li", [
                    _c("p", { staticClass: "font-bold text-lg" }, [
                      _vm._v("3:Purchase"),
                    ]),
                    _vm._m(2),
                    _c(
                      "p",
                      { staticClass: "mb-3 mt-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/vod/cancel-quote-item" },
                          },
                          [
                            _vm._v(
                              " ? How to cancel items not wish to purchase within a quote "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "px-3 text-sm font-semibold text-gray-700 underline",
                            attrs: { to: "/help/vod/delivery" },
                          },
                          [_vm._v(" ? Delivery ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-3" }, [
      _c("p", [
        _vm._v(
          "※新規映画登録において現在、本編ファイルをご登録いただくことはできません。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "本編ファイルは、販売成立後に納品ください。 納品方法はその際、別途おしらせいたします。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", {}, [
      _vm._v(
        " Create your Filmination account at first. Email address must be required."
      ),
      _c("br"),
      _vm._v(" *Buyer account is available after Filmination team approveld. "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", {}, [
      _vm._v(
        " Filmination team will inform of you the result whether contents holder agreed with your request or delcinled."
      ),
      _c("br"),
      _vm._v(
        " If contents holder agreed with your request, the order has been completed. "
      ),
      _c("br"),
      _vm._v(" You could select Delivery method when you request a quote."),
      _c("br"),
      _vm._v(" All delivery asset will be delivered after the negotiation. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }